import {
    API_APP_ID,
    API_CONCEPTS_SCHEMA_ID,
    API_TOKEN,
    API_URL,
} from 'core/constants/api-constants';
import { concept_map } from '../mapper/Concept.maps';
import { useGetAll } from './useQuery/useGetAll';
import { useMutation } from 'react-query';
import moment from 'moment';
import { CreateConceptForm } from 'core/models/CreateConceptForm';

export const useConcepts = () =>
    useGetAll({
        url: `${API_URL}/apps/${API_APP_ID}/schemas/${API_CONCEPTS_SCHEMA_ID}/data?limit=1000`,
        mapper: concept_map,
        globalMapperKey: 'data',
        headers: {
            Authorization: `Bearer ${API_TOKEN}`,
        },
    });

type UseSaveConceptProps = {
    onSuccess?: () => void;
};

export const useSaveConcept = ({ onSuccess }: UseSaveConceptProps = {}) =>
    useMutation({
        mutationFn: createConcept,
        onSuccess: onSuccess,
    });

const createConcept = async ({
    category,
    price,
    description,
}: CreateConceptForm) => {
    const saveConcept = {
        fields: {
            date: moment().format('YYYY-MM-DD'),
            category: { id: category },
            price: price,
            description: description,
        },
    };
    const response = await fetch(
        `${API_URL}/apps/${API_APP_ID}/schemas/${API_CONCEPTS_SCHEMA_ID}/data`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API_TOKEN}`,
            },
            body: JSON.stringify(saveConcept),
        }
    );

    if (!response.ok) {
        throw new Error('Error al guardar los datos');
    }

    return response.json();
};
