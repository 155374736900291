import React from 'react';
import { sumCategoryTotal } from '../core/utils/numbers';
import { Category } from '../core/models/Category';
import { Concept } from '../core/models/Concept';
import { Table } from './shared/Table';
import { formatNumber } from 'core/utils/number-util';
import { useTranslation } from 'react-i18next';
import { P } from './shared/P';

type Props = {
    concepts: Concept[];
    mappedCategories: Category[];
};

export const TotalsRow = ({ concepts, mappedCategories }: Props) => {
    const { t } = useTranslation();

    return (
        <Table.Row>
            <Table.Cell className="text-center">
                <P uppercase bold>
                    {t('concept.total')}
                </P>
            </Table.Cell>
            <Table.Cell className="text-center">
                <b>
                    {formatNumber(
                        concepts.reduce(
                            (totalPrice, concept) => totalPrice + concept.price,
                            0
                        )
                    )}
                    €
                </b>
            </Table.Cell>
            {mappedCategories.map((category, index) => (
                <Table.Cell key={`big-cell-${index}`} className="text-center">
                    <b>{formatNumber(sumCategoryTotal(concepts, category))}€</b>
                </Table.Cell>
            ))}
        </Table.Row>
    );
};
