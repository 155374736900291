import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from './locales/es.json';

const LANGUAGE_ES = 'es';

const STORAGE_LANGUAGE = 'language';
const DEFAULT_LANGUAGE = LANGUAGE_ES;

export const languages = [LANGUAGE_ES];

const currentLanguage =
    localStorage.getItem(STORAGE_LANGUAGE) || DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
    lng: currentLanguage,
    fallbackLng: languages,
    interpolation: {
        escapeValue: false,
    },
    resources: {
        es: {
            translation: es,
        },
    },
});

export const setLanguage = (code: string) => {
    i18n.changeLanguage(code);
    localStorage.setItem(STORAGE_LANGUAGE, code);
};

export default i18n;
