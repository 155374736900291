import React from 'react';
import { Concept } from '../core/models/Concept';
import { increment, sumMonthTotal } from '../core/utils/numbers';
import moment from 'moment';
import { Badge } from './shared/Badge';
import { IconBootstrap } from './shared/Icon';
import { formatPercentage } from 'core/utils/string-util';

type Props = {
    concepts: Concept[];
    currentDate: moment.Moment;
    prevDate: moment.Moment;
};

export const BadgeDangerTotal = ({
    concepts,
    currentDate,
    prevDate,
}: Props) => {
    const currentSum = sumMonthTotal(concepts, currentDate);
    const lastSum = sumMonthTotal(concepts, prevDate);
    const diff = increment(currentSum, lastSum);

    return (
        <Badge
            color="danger"
            className="d-flex d-inline-flex align-items-center"
        >
            {formatPercentage(diff, 2)}
            <IconBootstrap
                name="arrow-up"
                color="white"
                size="xs"
                className="ms-1"
            />
        </Badge>
    );
};
