import { useQuery } from 'react-query';
import { getAll } from '../../services/useQuery/resources.service';

type Props<T, R> = {
    url: string;
    mapper?: (data: R) => T;
    globalMapperKey?: string;
    headers?: HeadersInit;
};

export const useGetAll = <T, R>({ url, mapper, globalMapperKey, headers }: Props<T, R>) => {
    return useQuery(url, async () => {
        let response = await getAll({ url, headers });
        if (globalMapperKey) {
            response = response[globalMapperKey];
        }
        if (mapper) {
            return response.map(mapper);
        }
        return response;
    });
};
