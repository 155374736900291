import React from 'react';
import {
    decrement,
    sumMonthCategory,
} from '../core/utils/numbers';
import moment from 'moment';
import { Concept } from '../core/models/Concept';
import { Category } from '../core/models/Category';
import { Badge } from './shared/Badge';
import { IconBootstrap } from './shared/Icon';
import { formatPercentage } from 'core/utils/string-util';

type Props = {
    concepts: Concept[];
    currentDate: moment.Moment;
    prevDate: moment.Moment;
    category: Category;
};

export const BadgeSuccess = ({
    concepts,
    currentDate,
    prevDate,
    category,
}: Props) => {
    const currentSum = sumMonthCategory(concepts, currentDate, category);
    const lastSum = sumMonthCategory(concepts, prevDate, category);
    const diff = decrement(currentSum, lastSum);

    return (
        <Badge
            color="success"
            className="d-flex d-inline-flex align-items-center"
        >
            {formatPercentage(diff, 2)}
            <IconBootstrap
                name="arrow-down"
                color="white"
                size="xs"
                className="ms-1"
            />
        </Badge>
    );
};
