import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { AlignType, ColorTypeSmall } from './type/BootstrapType';

type RootProps = {
    children: ReactNode;
    color?: ColorTypeSmall;
    hover?: boolean;
    striped?: boolean;
    stripedColumns?: boolean;
    responsive?: boolean;
};

const Root = ({
    children,
    color,
    hover = false,
    striped = false,
    stripedColumns = false,
    responsive = false,
}: RootProps) => {
    const table = (
        <table
            className={clsx('table', {
                [`table-${color}`]: color,
                'table-hover': hover,
                'table-striped': striped,
                'table-striped-columns': stripedColumns,
            })}
        >
            {children}
        </table>
    );
    if (responsive) {
        return <div className="table-responsive">{table}</div>;
    }
    return <>{table}</>;
};

type HeaderProps = {
    children: ReactNode;
};

const Header = ({ children }: HeaderProps) => {
    return <thead>{children}</thead>;
};

type BodyProps = {
    children: ReactNode;
};

const Body = ({ children }: BodyProps) => {
    return <tbody>{children}</tbody>;
};

type RowProps = {
    children: ReactNode;
    color?: ColorTypeSmall;
    active?: boolean;
};

const Row = ({ children, color, active = false }: RowProps) => {
    return (
        <tr
            className={clsx({
                [`table-${color}`]: color,
                'table-active': active,
            })}
        >
            {children}
        </tr>
    );
};

type CellProps = {
    isHeader?: boolean;
    isActions?: boolean;
    children?: ReactNode;
    color?: ColorTypeSmall;
    active?: boolean;
    className?: string;
    onClick?: () => void;
    align?: AlignType;
};

const Cell = ({
    isHeader = false,
    isActions = false,
    children,
    color,
    active = false,
    className,
    onClick,
    align,
}: CellProps) => {
    const classNames = clsx(className, {
        [`table-${color}`]: color,
        [`align-${align}`]: align,
        'table-active': active,
        pointer: onClick,
        'd-flex align-items-center justify-content-end': isActions
    });
    if (isHeader) {
        return (
            <th className={classNames} onClick={onClick}>
                {children}
            </th>
        );
    }
    return (
        <td className={classNames} onClick={onClick}>
            {children}
        </td>
    );
};

export const Table = { Root, Header, Body, Row, Cell };
