import React from 'react';
import { ModalTable } from './ModalTable';
import { Category } from '../core/models/Category';
import { Moment } from 'moment';
import { Concept } from '../core/models/Concept';
import { Modal } from './shared/modal/Modal';

type Props = {
    clickedCategory: Category | null;
    clickedDate: Moment | null;
    concepts: Concept[];
    onClose: () => void;
};

export const DetailModal = ({
    clickedCategory,
    clickedDate,
    concepts,
    onClose,
}: Props) => {
    const shouldDisplay = clickedCategory && clickedDate;
    if (shouldDisplay) {
        return (
            <Modal.Root show>
                <Modal.Header
                    title={`${clickedDate.format('YYYY MMMM')} - ${clickedCategory.name}`}
                    onClose={onClose}
                />
                <Modal.Body>
                    <ModalTable
                        concepts={concepts}
                        clickedCategory={clickedCategory}
                        clickedDate={clickedDate}
                    />
                </Modal.Body>
            </Modal.Root>
        );
    }
    return null;
};
