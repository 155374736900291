const ORIGIN = 'firebase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debugAction = (...params: any[]) => console.info(...params);

type GetAllProps = {
    url: string;
    headers?: HeadersInit;
};

const getAll = async ({ url, headers }: GetAllProps) => {
    debugAction(ORIGIN, 'getAll', url);
    const response = await fetch(url, { headers });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
};

type GetOneProps = {
    url: string;
    headers?: HeadersInit;
};

const getOne = async ({ url, headers }: GetOneProps) => {
    debugAction(ORIGIN, 'getOne', url);
    const response = await fetch(url, { headers });
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
};

export { getAll, getOne };
