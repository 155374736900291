import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Logout } from 'pages/auth/Logout';
import { Login } from 'pages/auth/Login';
import { ROUTES } from 'core/constants/routes-constants';
import { ProtectedRoute } from 'components/shared/ProtectedRoute';
import { Spinner } from 'components/shared/Spinner';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { Home } from 'pages/Home';

export const App = () => {
    const [user, loading] = useAuthState(auth);

    if (loading) {
        return <Spinner />;
    }

    return (
        <Routes>
            <Route path={ROUTES.ROOT} element={user ? <Home /> : <Login navigateTo={ROUTES.HOME} />} />
            <Route
                path={ROUTES.LOGIN}
                element={<Login navigateTo={ROUTES.HOME} />}
            />
            <Route
                path={ROUTES.LOGOUT}
                element={<Logout navigateTo={ROUTES.LOGIN} />}
            />
            <Route
                path={ROUTES.HOME}
                element={
                    <ProtectedRoute user={user} defaultRoute={ROUTES.LOGIN}>
                        <Home />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};
