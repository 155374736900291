import React, { useState } from 'react';
import { Moment } from 'moment';
import { Category } from '../core/models/Category';
import { HomePageHeader } from '../components/HomePageHeader';
import { useCategories } from '../core/hooks/useCategories';
import { useConcepts } from '../core/hooks/useConcepts';
import { DetailModal } from '../components/DetailModal';
import { Spinner } from '../components/shared/Spinner';
import { HomePageTable } from 'components/HomePageTable';
import { Toast } from 'components/shared/Toast';
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t } = useTranslation();

    const [clickedDate, setClickedDate] = useState<Moment | null>(null);
    const [clickedCategory, setClickedCategory] = useState<Category | null>(
        null
    );
    const [flashes, setFlashes] = useState<
        {
            id: string;
            message: string;
        }[]
    >([]);

    const {
        data: categories,
        isLoading: isLoadingCategories,
        refetch: refetchCategories,
    } = useCategories();

    const {
        data: concepts,
        isLoading: isLoadingConcepts,
        refetch: refetchConcepts,
    } = useConcepts();

    const isLoading = isLoadingCategories || isLoadingConcepts;

    const updateTableData = () => {
        refetchCategories();
        refetchConcepts();
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <DetailModal
                clickedCategory={clickedCategory}
                clickedDate={clickedDate}
                concepts={concepts}
                onClose={() => {
                    setClickedCategory(null);
                    setClickedDate(null);
                }}
            />
            <div className="px-3">
                <HomePageHeader
                    categories={categories}
                    onSendConcept={() => {
                        updateTableData();
                        setFlashes([
                            ...flashes,
                            {
                                id: crypto.randomUUID(),
                                message: t('toast.concept.add.success.message'),
                            },
                        ]);
                    }}
                />
                <HomePageTable
                    categories={categories}
                    concepts={concepts}
                    onCellClicked={(category: Category, date: Moment) => {
                        setClickedCategory(category);
                        setClickedDate(date);
                    }}
                />
            </div>
            <Toast.Container>
                {flashes.map(({ id, message }) => (
                    <Toast.Item key={id} color="success" closable>
                        {message}
                    </Toast.Item>
                ))}
            </Toast.Container>
        </>
    );
};
