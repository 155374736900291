import 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const config = {
    apiKey: 'AIzaSyCslbtboR08pCK6kLOAZb_CXoAgoKjRd04',
    authDomain: 'money-bdbfa.firebaseapp.com',
    databaseURL:
        'https://money-bdbfa-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'money-bdbfa',
    storageBucket: 'money-bdbfa.appspot.com',
    messagingSenderId: '180070772939',
    appId: '1:180070772939:web:1ec15aedce7954f0242b4e',
    measurementId: 'G-C6WK2NS0GZ',
};

const app = initializeApp(config);
const auth = getAuth(app);
const db = getDatabase(app);

const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        return await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
    }
};

const logout = () => {
    signOut(auth);
};

export { app, auth, db, logInWithEmailAndPassword, logout };
