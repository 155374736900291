import {
    API_APP_ID,
    API_CATEGORIES_SCHEMA_ID,
    API_TOKEN,
    API_URL,
} from 'core/constants/api-constants';
import { category_map } from '../mapper/Category.maps';
import { useGetAll } from './useQuery/useGetAll';

export const useCategories = () =>
    useGetAll({
        url: `${API_URL}/apps/${API_APP_ID}/schemas/${API_CATEGORIES_SCHEMA_ID}/data?sort=name&limit=1000`,
        mapper: category_map,
        globalMapperKey: 'data',
        headers: {
            Authorization: `Bearer ${API_TOKEN}`,
        },
    });
