import React from 'react';
import { Category } from '../core/models/Category';
import { Concept } from '../core/models/Concept';
import { average, sumMonthCategory } from '../core/utils/numbers';
import moment from 'moment';
import { Table } from './shared/Table';
import { formatNumber } from 'core/utils/number-util';
import { useTranslation } from 'react-i18next';
import { P } from './shared/P';

type Props = {
    mappedCategories: Category[];
    rowKeysArray: number[];
    concepts: Concept[];
};

export const AveragesRow = ({
    mappedCategories,
    rowKeysArray,
    concepts,
}: Props) => {
    const { t } = useTranslation();

    return (
        <Table.Row>
            <Table.Cell className="text-center">
                <P uppercase bold>
                    {t('concept.average')}
                </P>
            </Table.Cell>
            <Table.Cell />
            {mappedCategories.map((category, index) => (
                <Table.Cell key={index} className="text-center">
                    <b>
                        {formatNumber(
                            average(
                                rowKeysArray.map((key) =>
                                    sumMonthCategory(
                                        concepts,
                                        moment().subtract(key, 'months'),
                                        category
                                    )
                                )
                            )
                        )}
                        €
                    </b>
                </Table.Cell>
            ))}
        </Table.Row>
    );
};
