import { Moment } from 'moment';
import { Category } from '../models/Category';
import { Concept } from '../models/Concept';

export const increment = (start: number, end: number): number => {
    if (start === 0 && end === 0) {
        return 0;
    }
    if (start === 0) {
        return 0;
    }
    if (end === 0) {
        return 100;
    }
    return 100 - (end / start) * 100;
};

export const decrement = (start: number, end: number): number => {
    if (start === 0 && end === 0) {
        return 0;
    }
    if (start === 0) {
        return 100;
    }
    if (end === 0) {
        return 0;
    }
    return 100 - (start / end) * 100;
};

export const average = (array: number[]): number => {
    const filteredArray = array.filter((element) => element > 0);
    const sum = filteredArray.reduce((a, b) => a + b, 0);
    const avg = sum / filteredArray.length || 0;
    return avg;
};

export const filterByMonth = (
    concepts: Concept[],
    month: Moment
): Concept[] => {
    if (!concepts) {
        return [];
    }
    return (concepts || []).filter((concept) =>
        concept.date.format('YYYY-MM').includes(month.format('YYYY-MM'))
    );
};

export const sumMonthTotal = (concepts: Concept[], month: Moment): number => {
    if (!concepts) {
        return 0;
    }
    return filterByMonth(concepts, month).reduce(
        (totalPrice, concept) => totalPrice + concept.price,
        0
    );
};

export const filterByCategory = (
    concepts: Concept[],
    category: Category
): Concept[] => {
    if (!concepts) {
        return [];
    }
    return (concepts || []).filter(
        (concept) => concept.category === category.name
    );
};

export const filterByMonthCategory = (
    concepts: Concept[],
    month: Moment,
    category: Category
): Concept[] => {
    if (!concepts) {
        return [];
    }
    return (concepts || []).filter(
        (concept) =>
            concept.category === category.name &&
            concept.date.format('YYYY-MM').includes(month.format('YYYY-MM'))
    );
};

export const sumCategoryTotal = (
    concepts: Concept[],
    category: Category
): number => {
    if (!concepts) {
        return 0;
    }
    return filterByCategory(concepts, category).reduce(
        (totalPrice, concept) => totalPrice + concept.price,
        0
    );
};

export const sumMonthCategory = (
    concepts: Concept[],
    month: Moment,
    category: Category
): number => {
    if (!concepts) {
        return 0;
    }
    return filterByMonthCategory(concepts, month, category).reduce(
        (totalPrice, concept) => totalPrice + concept.price,
        0
    );
};

export const monthDiff = (d1: Moment | null, d2: Moment | null) => {
    if (!d1 || !d2) {
        return 0;
    }
    return Math.abs(d2.diff(d1, 'months')) + 1;
};
