import React from 'react';
import { Category } from '../core/models/Category';
import { Table } from './shared/Table';
import { useTranslation } from 'react-i18next';
import { P } from './shared/P';

type Props = {
    mappedCategories: Category[];
};

export const MainTableHeader = ({ mappedCategories }: Props) => {
    const { t } = useTranslation();

    return (
        <Table.Row>
            <Table.Cell isHeader />
            <Table.Cell isHeader className="text-center">
                <P uppercase>{t('concept.total')}</P>
            </Table.Cell>
            {mappedCategories.map((category, index) => (
                <Table.Cell isHeader key={index} className="text-center">
                    {category.name}
                </Table.Cell>
            ))}
        </Table.Row>
    );
};
