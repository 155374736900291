import React from 'react';
import { Table } from './shared/Table';
import { sumMonthCategory } from 'core/utils/numbers';
import { BadgeSuccess } from './BadgeSuccess';
import { BadgeDanger } from './BadgeDanger';
import { Concept } from 'core/models/Concept';
import moment from 'moment';
import { Category } from 'core/models/Category';
import { formatNumber } from 'core/utils/number-util';

type Props = {
    concepts: Concept[];
    lastDate: moment.Moment;
    keyLoop: number;
    category: Category;
    onClick: () => void;
};

export const TableCell = ({
    concepts,
    lastDate,
    keyLoop: key,
    category,
    onClick,
}: Props) => {
    const total = sumMonthCategory(
        concepts,
        moment(lastDate).subtract(key, 'months'),
        category
    );
    const canClick = total !== 0;

    const displayBadgeSuccess = (category: Category) =>
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) <
            sumMonthCategory(
                concepts,
                moment(lastDate).subtract(key + 1, 'months'),
                category
            ) &&
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) !== 0 &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );

    const displayBadgeDanger = (category: Category) =>
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) >=
            sumMonthCategory(
                concepts,
                moment(lastDate).subtract(key + 1, 'months'),
                category
            ) &&
        sumMonthCategory(
            concepts,
            moment(lastDate).subtract(key, 'months'),
            category
        ) !== 0 &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );

    return (
        <Table.Cell
            className="text-center"
            onClick={canClick ? onClick : undefined}
        >
            {canClick ? `${formatNumber(total)}€` : ''}
            <div>
                {displayBadgeSuccess(category) && (
                    <BadgeSuccess
                        concepts={concepts}
                        currentDate={moment(lastDate).subtract(key, 'month')}
                        prevDate={moment(lastDate).subtract(key + 1, 'month')}
                        key={key}
                        category={category}
                    />
                )}
                {displayBadgeDanger(category) && (
                    <BadgeDanger
                        concepts={concepts}
                        currentDate={moment(lastDate).subtract(key, 'month')}
                        prevDate={moment(lastDate).subtract(key + 1, 'month')}
                        key={key}
                        category={category}
                    />
                )}
            </div>
        </Table.Cell>
    );
};
