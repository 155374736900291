import React from 'react';
import { Category } from '../core/models/Category';
import { useForm } from 'react-hook-form';
import { Form } from './shared/form/Form';
import { Button } from './shared/button/Button';
import { Select } from './shared/form/Select';
import { Input } from './shared/form/Input';
import { useTranslation } from 'react-i18next';
import { CreateConceptForm } from 'core/models/CreateConceptForm';
import { H2 } from './shared/H';
import { useSaveConcept } from 'core/hooks/useConcepts';

type Props = {
    categories: Category[];
    onSendConcept: () => void;
};

export const HomePageHeader = ({ categories, onSendConcept }: Props) => {
    const { t } = useTranslation();

    const methods = useForm<CreateConceptForm>();

    const { mutate: sendConcept, isLoading } = useSaveConcept({
        onSuccess: () => {
            methods.reset();
            onSendConcept();
        },
    });

    return (
        <div className="d-flex flex-column flex-md-row">
            <div className="me-4">
                <H2 className="my-2">Money</H2>
            </div>
            <Form
                methods={methods}
                onSubmit={sendConcept}
                className="d-flex flex-column flex-md-row column-gap-3 mt-2"
            >
                <Select
                    name="category"
                    options={categories.map((category) => ({
                        key: category.id,
                        name: category.name,
                    }))}
                    emptyOption
                    placeholder={t('concept.fields.category')}
                    required
                    disabled={isLoading}
                />
                <Input
                    type="number"
                    name="price"
                    placeholder={t('concept.fields.price')}
                    required
                    positive
                    disabled={isLoading}
                />
                <Input
                    name="description"
                    placeholder={t('concept.fields.description')}
                    disabled={isLoading}
                />
                <div>
                    <Button submit fullWidth disabled={isLoading}>
                        {t('concept.button.add')}
                    </Button>
                </div>
            </Form>
        </div>
    );
};
