import React, { ReactNode } from 'react';
import {
    ColorTypeSmall,
    MarginType,
    PlacementType,
} from './type/BootstrapType';
import clsx from 'clsx';

type ContainerProps = {
    children?: ReactNode;
    placement?: PlacementType;
};

const Container = ({
    children,
    placement = 'bottom-right',
}: ContainerProps) => {
    return (
        <div
            className={clsx('toast-container position-fixed p-3', {
                'top-0 start-0': placement === 'top-left',
                'top-0 start-50': placement === 'top-center',
                'top-0 end-0': placement === 'top-right',
                'top-50 start-0': placement === 'middle-left',
                'top-50 start-50': placement === 'middle-center',
                'top-50 end-0': placement === 'middle-right',
                'bottom-0 start-0': placement === 'bottom-left',
                'bottom-0 start-50': placement === 'bottom-center',
                'bottom-0 end-0': placement === 'bottom-right',
            })}
        >
            {children}
        </div>
    );
};

type ItemProps = {
    children: ReactNode;
    m?: MarginType;
    mt?: MarginType;
    mb?: MarginType;
    mx?: MarginType;
    my?: MarginType;
    closable?: boolean;
    color?: ColorTypeSmall;
};

const Item = ({
    children,
    m,
    mt,
    mb,
    mx,
    my,
    closable = false,
    color = 'primary',
}: ItemProps) => {
    return (
        <div
            className={clsx('toast align-items-center border-0 fade show', {
                [`m-${m}`]: m !== undefined,
                [`mt-${mt}`]: mt !== undefined,
                [`mb-${mb}`]: mb !== undefined,
                [`mx-${mx}`]: mx !== undefined,
                [`my-${my}`]: my !== undefined,
                [`text-bg-${color}`]: color,
            })}
            role="alert"
        >
            <div className="d-flex">
                <div className="toast-body">{children}</div>
                {closable && (
                    <button
                        type="button"
                        className="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    ></button>
                )}
            </div>
        </div>
    );
};

export const Toast = { Container, Item };
