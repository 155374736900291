import React from 'react';
import moment from 'moment';
import { sumMonthTotal } from '../core/utils/numbers';
import { Category } from '../core/models/Category';
import { Concept } from '../core/models/Concept';
import { BadgeSuccessTotal } from './BadgeSuccessTotal';
import { BadgeDangerTotal } from './BadgeDangerTotal';
import { Table } from './shared/Table';
import { TableCell } from './TableCell';
import { formatNumber } from 'core/utils/number-util';

type Props = {
    lastDate: moment.Moment;
    keyLoop: number;
    concepts: Concept[];
    mappedCategories: Category[];
    onCellClick: (category: Category) => void;
};

export const TableRow = ({
    lastDate,
    keyLoop: key,
    concepts,
    mappedCategories,
    onCellClick,
}: Props) => {
    const displayBadgeSuccessTotal =
        sumMonthTotal(concepts, moment(lastDate).subtract(key, 'months')) <
            sumMonthTotal(
                concepts,
                moment(lastDate).subtract(key + 1, 'months')
            ) &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    const displayBadgeDangerTotal =
        sumMonthTotal(concepts, moment(lastDate).subtract(key, 'months')) >=
            sumMonthTotal(
                concepts,
                moment(lastDate).subtract(key + 1, 'months')
            ) &&
        moment().isAfter(
            moment(lastDate).subtract(key, 'months').format('YYYY-MM-DD')
        );
    return (
        <Table.Row>
            <Table.Cell className="text-center">
                <div>
                    {`${moment(lastDate)
                        .subtract(key, 'months')
                        .format('YYYY MMMM')
                        .substring(0, 8)}`}
                </div>
            </Table.Cell>
            <Table.Cell className="text-center">
                <b>
                    {formatNumber(
                        sumMonthTotal(
                            concepts,
                            moment(lastDate).subtract(key, 'months')
                        )
                    )}
                    €
                </b>
                <div>
                    {displayBadgeSuccessTotal && (
                        <BadgeSuccessTotal
                            concepts={concepts}
                            currentDate={moment(lastDate).subtract(key, 'month')}
                            prevDate={moment(lastDate).subtract(key + 1, 'month')}
                            key={key}
                        />
                    )}
                    {displayBadgeDangerTotal && (
                        <BadgeDangerTotal
                            concepts={concepts}
                            currentDate={moment(lastDate).subtract(key, 'month')}
                            prevDate={moment(lastDate).subtract(key + 1, 'month')}
                            key={key}
                        />
                    )}
                </div>
            </Table.Cell>
            {mappedCategories.map((category, index) => (
                <TableCell
                    key={`big-cell-${index}`}
                    keyLoop={key}
                    concepts={concepts}
                    lastDate={lastDate}
                    category={category}
                    onClick={() => onCellClick(category)}
                />
            ))}
        </Table.Row>
    );
};
