export const percentage = (number: number, total: number) =>
    (number * 100) / total;

export const toNumber = (value: string | null | undefined): number | null =>
    value === null || value === undefined || value === ''
        ? null
        : parseFloat(value.replace(',', '.'));

export const formatNumber = (number: number): string => {
    return (Math.round(number * 100) / 100).toFixed(2);
};
