import React from 'react';
import { Table } from './shared/Table';
import { MainTableHeader } from './MainTableHeader';
import { TableRow } from './TableRow';
import { AveragesRow } from './AveragesRow';
import { TotalsRow } from './TotalsRow';
import { Category } from 'core/models/Category';
import moment, { Moment } from 'moment';
import { monthDiff } from 'core/utils/numbers';
import { Concept } from 'core/models/Concept';

type Props = {
    categories: Category[];
    concepts: Concept[];
    onCellClicked: (category: Category, date: Moment) => void;
};

export const HomePageTable = ({
    categories,
    concepts,
    onCellClicked,
}: Props) => {
    const rowKeys = (firstDate: Moment | null, lastDate: Moment | null) => {
        if (firstDate == null || lastDate == null) {
            return [];
        }
        const array = Array.from(
            Array(monthDiff(moment(lastDate), firstDate)).keys()
        );
        array.push(array.length);
        return array;
    };

    const firstDate = moment().subtract(20, 'month');
    const lastDate = moment();

    const rowKeysArray = rowKeys(firstDate, lastDate);

    return (
        <Table.Root responsive striped hover>
            <Table.Header>
                <MainTableHeader mappedCategories={categories} />
            </Table.Header>
            <Table.Body>
                {rowKeysArray.map((key, index) => (
                    <TableRow
                        key={`table-row-${index}`}
                        lastDate={lastDate}
                        keyLoop={key}
                        concepts={concepts}
                        mappedCategories={categories}
                        onCellClick={(category: Category) => {
                            onCellClicked(
                                category,
                                moment(lastDate).subtract(key, 'months')
                            );
                        }}
                    />
                ))}
                <AveragesRow
                    mappedCategories={categories}
                    rowKeysArray={rowKeysArray}
                    concepts={concepts}
                />
                <TotalsRow concepts={concepts} mappedCategories={categories} />
            </Table.Body>
        </Table.Root>
    );
};
